.AddPost-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddPost-Username,
.AddPost-Title,
.AddPost-Type,
.AddPost-Content,
.AddPost-Media {
  padding: 8px 0;
  width: 100%;
  max-width: 300px;
}

.AddPost-Username input,
.AddPost-Title input,
.AddPost-Type select,
.AddPost-Media input {
    height: 20px;
    width: 100%;
    max-width: 300px;
}

