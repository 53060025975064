.Post-Container {
  margin-top: 30px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 4px 8px;
}

.Post-Username {
  font-style: italic;
  padding: 0 0 5px;
}
.Post-Title {
  font-weight: bold;
  padding: 0 0 5px;
}

.Post-Content img {
  width: 100%;
  height: 100%;
}
